import React from "react";
import {  ScaleLoader } from "react-spinners";

function Loader({ color }) {
  return (
    <>
      <ScaleLoader color={color ? color : "white"} size={20} />
    </>
  );
}

export default Loader;
