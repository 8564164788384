import React, { useEffect } from "react";
import { useState } from "react";
import CreateMarshal from "./CreateMarshal";
import configData from "../../config.json";

import Modal from "react-modal";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import Table from "../common/Table";
import { HiOutlineSearch } from "react-icons/hi";
import ModalContainer from "../common/ModalContainer";
import AddNewReport from "../Forms.js/AddNewReport";
import AddMarshal from "../Forms.js/AddMarshal";
import { getAllMarshals } from "../../utils/marshals/getAllMarshal";
import ConfirmationModal from "../common/ConfirmationModal";
import { deleteReport } from "../../utils/reports/deleteReport";
import { deleteMarshal } from "../../utils/marshals/deleteMarshal";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import EmailSender from "./emailComoponent";
import { massAction } from "../../utils/emailTemplates/massActions";
import { ScaleLoader } from "react-spinners";
import SMTable from "../common/SMTable";

function ManageMarshalls() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    // getAllMarshals,
    getAllUnits,
    getRoles,
    marshall,
    setMarshall,
    loadingSm, 
    setLoadingSm,
    units,
    filterMarshalByUnitId,
    auth,
    userRoles,
  } = useAuth();
  // useEffect(() => {
  //   setLoading(true);
  //   getAllMarshals()
  //     .then((res) => {
  //       setLoading(false);
  //       setMarshall(res?.data?.data);
  //     })
  //     .catch(() => {
  //       console.log("error fetching marshals");
  //     });
  //   getAllUnits();
  //   getRoles();
  // }, []);

  const unitId = auth?.userDetails?.unitId;
  // console.log(auth);

  useEffect(() => {
    setLoading(true);
    if (auth?.roles === "UNITCOORDINATOR") {
      filterMarshalByUnitId(unitId);
      setLoading(false);
    } else {
      getAllMarshals()
        .then((res) => {
          setLoading(false);
          setMarshall(res?.data?.data);
        })
        .catch(() => {
          console.log("error fetching marshals");
        });
    }
    getAllUnits();
    getRoles();
  }, []);

  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  // const [rowData, setRowData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const filterMarshalByUnit = (unit) => {
  //   setSelectedUnit(unit);
  //   if (selectedUnit !== "") {
  //     filterMarshalByUnitId(unit);
  //   } else {
  //     getAllMarshals();
  //   }
  // };

  const filterMarshalByUnit = (unit) => {
  setSelectedUnit(unit);
  if (unit !== null && unit !== "") {
    filterMarshalByUnitId(unit);
  } else {
    getAllMarshals()
        .then((res) => {
          setLoading(false);
          setMarshall(res?.data?.data);
        })
        .catch(() => {
          console.log("error fetching marshals");
        });
  }
};

  // const UnitName = rowData?.Unit?.Name;
  // const marshallinThisUnit = marshall.filter((item) => item.unit === UnitName);

  // auth?.roles === "UnitCoordinator" && (setMarshall(marshallinThisUnit))

  // console.log("user", userRoles);
  const navigate = useNavigate();
  const handleMarshalDelete = () => {
    setLoading(true);
    deleteMarshal(rowData?.id)
      .then((res) => {
        setLoading(false);
        setOpenModal({ modalName: "", showModal: false });
        toast.success("Marshal deleted successfully");
        if (auth?.roles === "UNITCOORDINATOR") {
          filterMarshalByUnitId(unitId);
          setMarshall(res?.data?.data);
        } else {
          getAllMarshals()
            .then((res) => {
              setMarshall(res?.data?.data);
            })
            .catch(() => {
              console.log("error fetching marshals");
              setLoading(false);
              navigate("/login")
            });
        }
      })
      .catch((err) => {
        console.log("error deleting report", err);
        setLoading(false);
      });
  };

  // console.log(selectedRows +" selected role")

  // disbale/enable account
  const [actionType,setActionType] = useState("")
  const [isloading, setIsloading] = useState()

  console.log(actionType)
  const data ={
    actionType:actionType,
    emailTemplate:"",
    smIds:selectedRows,
    currentUnitId:0,
    newUnitId:0,
  }

  const submitData =()=>{
    setLoading(true);
    massAction(data).then((res)=>{  
      console.log( res +"succes")
      toast.success("Succesfully")
      getAllMarshals()
        .then((res) => {
          setMarshall(res?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          console.log("error fetching marshals");
        });
      setOpenModal({
        modalName:"",
        showModal:false
      })
      setLoading(false)
  }).catch((err)=>{
      console.log(err)
      toast.error("Error changing Unot")
      setOpenModal({
        modalName:"",
        showModal:false
      })
      setLoading(false)
  })
  }

  
  const [showActive, setShowActive] = useState(false);

  const handleShowActive = () => {
    setShowActive(!showActive); // Toggle active state
    if (!showActive) {
      // Filter only active marshals
      const activeMarshals = marshall.filter((m) => m.isActive === false);
      setMarshall(activeMarshals);
    } else {
      // Reset to show all marshals
      setLoading(true);
      getAllMarshals()
        .then((res) => {
          setMarshall(res?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          console.log("error fetching marshals");
        });
    }
  };


  return (
    <div className="min-h-[92vh]">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-[30px]">
        <div className="relative flex flex-col lg:flex-row gap-[21px] items-center">
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
          {auth?.roles === "ADMINISTRATOR" && (
            <select
              className="rounded-[8px] border h-[45px] px-4 w-full lg:w-[177px] border-[#A6A6A6] outline-none"
              value={selectedUnit}
              onChange={(e) => filterMarshalByUnit(e.target.value)}
            >
              <option value="" selected>
                Select Unit
              </option>
              {units?.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
          )}
          {auth?.roles === "ADMINISTRATOR" && (
            <select
              className="rounded-[8px] border h-[45px] px-4 w-full lg:w-[177px] border-[#A6A6A6] outline-none"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value="" selected>
                Select Role
              </option>
              {/* <option value={false} selected>
                unVerified
              </option> */}
              {userRoles?.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          )}

{auth?.roles === "ADMINISTRATOR" && (
  <div className="flex items-center">
  <span className="mr-2 text-gray-700">Show InActive</span>

  <label className="relative inline-flex items-center cursor-pointer">
    <input 
      type="checkbox" 
      className="sr-only peer" 
      checked={showActive} 
      onChange={handleShowActive} 
    />

    {/* Toggle Background */}
    <div className="w-11 h-6 bg-gray-300 rounded-full peer-checked:bg-green-500 transition-colors duration-300 relative">
      
      {/* Toggle Ball */}
      <div 
        className={` ${showActive ? (`left-5`) : (`left-1`)} absolute  top-1 w-4 h-4 bg-white rounded-full shadow-md transition-all duration-300 peer-checked:left-6 `}
      ></div>

    </div>
  </label>
</div>

        
         
          )}

        </div>
        <button
          className="rounded-[8px] bg-[#0D6EFD] text-[16px] text-white px-3 py-[10px] flex items-center gap-2 mt-[21px] lg:mt-0"
          onClick={() => setModalIsOpen(true)}
        >
          Add a New Marshal <span className="text-[28px] font-semibold">+</span>
        </button>
      </div>

{
  selectedRows.length > 0 &&(
       <div className=" w-full flex gap-x-5 ">
      <button onClick={()=>setOpenModal({
        showModal:true,
        modalName:"email"
      })} className=" text-white bg-[#0D6EFD] rounded-[5px] py-[5px] font-medium px-3">Send Mail</button>
      <button onClick={()=>setOpenModal({
        showModal:true,
        modalName:"units"
      })} className=" text-white bg-[#0D6EFD] rounded-[5px] py-[5px] font-medium px-3">Change Unit</button>
      <button onClick={()=>{
        setOpenModal({
          showModal:true,
          modalName:"disable"
          
        })
        setActionType("DisableAccount");
      }
      } className=" text-white bg-[#0D6EFD] rounded-[5px] py-[5px] font-medium px-3">Disable Account</button>
      <button onClick={()=>{
        setActionType("EnableAccount");
        setOpenModal({
          showModal:true,
          modalName:"enable"
        });
      }} className=" text-white bg-[#0D6EFD] rounded-[5px] py-[5px] font-medium px-3">Enable Account</button>
      <button
        onClick={()=>{
          // setActionType("EnableAccount");
          setOpenModal({
            showModal:true,
            modalName:"changeUC"
          });
        }} 
      className=" text-white bg-[#0D6EFD] rounded-[5px] py-[5px] font-medium px-3">Assign Unit Coordinator</button>
      <button
        onClick={()=>{
          // setActionType("EnableAccount");
          setOpenModal({
            showModal:true,
            modalName:"changeDUC"
          });
        }} 
      className=" text-white bg-[#0D6EFD] rounded-[5px] py-[5px] font-medium px-3">Assign Deputy Unit Coordinator</button>
    </div>  
  )
}
      <SMTable
        state={loading}
        check="true"
        header={[
          { name: "First Name", identifier: "firstName" },
          { name: "Last Name", identifier: "lastName" },
          // { name: "S/M Number", identifier: "smNumber" },
          { name: "Email Address", identifier: "emailAddress" },
          { name: "Status", identifier: "isActive" },
          // { name: "Status", identifier: "Active" },
        ]}
        // data={[...marshall]?.filter((user) =>
        //   selectedRole ? user?.userRole === selectedRole : user
        
        // )}
        data={marshall?.filter(user => !selectedRole || user?.userRole === selectedRole)}

        options={{
          variant: "primary",
          tableStyle: "",
          allowSorting: true,
          allowActions: true,
          actions: {
            edit: true,
            view: true,
            delete: true,
            reset: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
        isActive={isActive}
        setIsActive={setIsActive}
      />
      {modalIsOpen && (
        <ModalContainer>
          <AddMarshal loading={loading} closeModal={setModalIsOpen} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "edit" && (
        <ModalContainer>
          <AddMarshal
            loading={loading}
            mode="edit"
            closeModal={setOpenModal}
            rowData={rowData}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "view" && (
        <ModalContainer>
          <AddMarshal
            loading={loading}
            mode="view"
            closeModal={setOpenModal}
            rowData={rowData}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "delete" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to delete this user?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={handleMarshalDelete}
            loading={loading}
          />
        </ModalContainer>
      )}

{openModal.showModal && openModal.modalName === "disable" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to disable this users?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={submitData}
            loading={loading}
          />
        </ModalContainer>
      )}

{openModal.showModal && openModal.modalName === "enable" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to enable this users?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={submitData}
            loading={loading}
          />
        </ModalContainer>
      )}

      {openModal.showModal && openModal.modalName === "reset" && (
        <ModalContainer height="auto">
          <PasswordResetView
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </ModalContainer>
      )}
      {
        openModal.showModal && openModal.modalName === "email" && (
          <ModalContainer height="auto">
            <EmailSender
            selectedRows={selectedRows}
            openModal={openModal}
            setOpenModal={setOpenModal}
            />
          </ModalContainer>
        )
      }

{
        openModal.showModal && openModal.modalName === "units" && (
          <ModalContainer height="auto">
            <ChangeUnint
            selectedRows={selectedRows}
            units={units}
            openModal={openModal}
            setOpenModal={setOpenModal}
            marshall={marshall}
            />
          </ModalContainer>
        )
      }

{
        openModal.showModal && openModal.modalName === "changeUC" && (
          <ModalContainer height="auto">
            <ChangeUnintCoordinator
            marshall={[...marshall]}
            selectedRows={selectedRows}
            units={units}
            openModal={openModal}
            setOpenModal={setOpenModal}
            />
              {/* <ConfirmationModal
              message="Are you sure you want to to Assign this user as unit coordinator?"
              handleCancel={() =>
                setOpenModal({ modalName: "", showModal: false })
              }
            handleConfirm={submitData}
            loading={loading}
          /> */}
          </ModalContainer>
        )
      }

{
        openModal.showModal && openModal.modalName === "changeDUC" && (
          <ModalContainer height="auto">
            <ChangeDeputyUnintCoordinator
            marshall={[...marshall]}
            selectedRows={selectedRows}
            units={units}
            openModal={openModal}
            setOpenModal={setOpenModal}
            />
          </ModalContainer>
        )
      }
    </div>
  );
}

export default ManageMarshalls;

const PasswordResetView = ({ openModal, setOpenModal }) => {
  return (
    <form className="p-10">
      <div className="mb-5">
        <label className="font-semibold text-[16px] mb-[8px]">
          New Password
        </label>
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="password"
          type="password"
          // value={formData.smNumber}
          // onChange={handleChange}
          required
          autoComplete="new-password"
        />
      </div>
      <div className="mb-5">
        <label className="font-semibold text-[16px] mb-[8px]">
          Confirm New Password
        </label>
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="confirmPassword"
          type="password"
          // value={formData.smNumber}
          // onChange={handleChange}
          required
          autoComplete="new-password"
        />
      </div>
      <div className="flex items-center justify-center gap-5 w-full">
        <button
          className="outline-none py-4 rounded-md border w-1/2 text-[15px]"
          onClick={() => setOpenModal({ modalName: "", showModal: false })}
          type="button"
        >
          Cancel
        </button>
        <button className="outline-none py-4 rounded-md w-1/2 bg-[#0D6EFD] text-white text-[15px]">
          Reset
        </button>
      </div>
    </form>
  );
};


const ChangeUnint= ({ openModal, selectedRows, setOpenModal, units }) => {

  const [selectedUnit, setSelectedUnit] = useState()
  const [loading, setLoading] = useState()
  

  console.log(selectedUnit +"units selected")

    const data ={
      actionType:"ChangeUnit",
      emailTemplate:"",
      smIds:selectedRows,
      currentUnitId:0,
      newUnitId:Number(selectedUnit),
    }
    const submitData =()=>{
      setLoading(true);
      massAction(data).then((res)=>{  
        console.log( res +"succes")
        toast.success("Unit Changed Succesfully")
        setOpenModal({
          modalName:"",
          showModal:false
        })
        setLoading(false)
    }).catch((err)=>{
        console.log(err)
        toast.error("SM not in the same Unit")
        setOpenModal({
          modalName:"",
          showModal:false
        })
        setLoading(false)
    })
    }

  return (
    <div className="p-10 w-full">
      <h1 className=" uppercase font-bold mb-5 w-full text-center"> Change Marshal Unit</h1>
      <div className="mb-5 w-full">
        <select value={selectedUnit} onChange={(e)=>setSelectedUnit(e.target.value)} className=" w-full py-3">
          <option>Select Unit</option>
          {
            units?.map((unit)=>(
              <option key={unit?.id} value={unit?.id}  className=" w-full ">{unit?.name}</option>
            ))
          }
        </select>
      </div>
      <div className="flex items-center justify-center gap-5 w-full">
        <button
          className="outline-none py-2 rounded-md border w-1/2 "
          onClick={() => setOpenModal({ modalName: "", showModal: false })}
          type="button"
        >
          Cancel
        </button>
        <button onClick={()=>submitData()} className="outline-none py-2 rounded-md w-1/2 bg-[#0D6EFD] text-white ">
         {loading ? <ScaleLoader color="white"/>:"Submit"}
        </button>
      </div>
    </div>
  );
};



const ChangeUnintCoordinator= ({ openModal,marshall, selectedRows, setOpenModal, units }) => {

  const [selectedUnit, setSelectedUnit] = useState()
  const [loading, setLoading] = useState()
  const [searchQuery, setSearchQuery] = useState()

  // const filteredSM = marshall?.find((sm)=> sm?.id == selectedRows[0])
  // console.log(filteredSM +"found")
  
  

  console.log(selectedUnit +"units selected")

    const data ={
      actionType:"AssignUnitCoodinator",
      emailTemplate:"",
      smIds:selectedRows,
      currentUnitId:0,
      newUnitId:selectedUnit,
    }
    const submitData =()=>{
      setLoading(true);
      massAction(data).then((res)=>{  
        console.log( res +"succes")
        toast.success("Assigned Succesfully")
        setOpenModal({
          modalName:"",
          showModal:false
        })
        setLoading(false)
    }).catch((err)=>{
        console.log(err)
        toast.error("SM not in the same Unit")
        setOpenModal({
          modalName:"",
          showModal:false
        })
        setLoading(false)
    })
    }
    // const filteredSM = marshall?.filter((sm)=> `${sm?.firstName} ${sm?.lastName}`== searchQuery)
  return (
    <div className="p-10 w-full">
      {/* <div>
        <select>
          <option onChange={(e)=>setsel}>Select a special marshial</option>
          {
            filteredSM?.map((sm)=>(
            <option value={sm?.id} key={sm?.id}>{sm?.firstName  + " " + sm?.lastName}</option>
            ))
          }
        </select>
      </div> */}
{/* 
      <div>
      <input type="text" placeholder="Search User" value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)}/>
      </div> */}


<h1 className=" uppercase font-bold w-full text-center mb-5">Assign as Unit Coordinator</h1>

      <h1 className=" uppercase font-bold mb-5"> Select Unit</h1>
      <div className="mb-5 w-full">
        <select value={selectedUnit} onChange={(e)=>setSelectedUnit(e.target.value)} className=" w-full py-3">
          <option>Select Unit</option>
          {
            units?.map((unit)=>(
              <option key={unit?.id} value={unit?.id}  className=" w-full ">{unit?.name}</option>
            ))
          }
        </select>
      </div>
      <div className="flex items-center justify-center gap-5 w-full">
        <button
          className="outline-none py-2 rounded-md border w-1/2 "
          onClick={() => setOpenModal({ modalName: "", showModal: false })}
          type="button"
        >
          Cancel
        </button>
        <button onClick={()=>submitData()} className="outline-none py-2 rounded-md w-1/2 bg-[#0D6EFD] text-white ">
         {loading ? <ScaleLoader color="white"/>:"Submit"}
        </button>
      </div>
    </div>
  );
};


const ChangeDeputyUnintCoordinator= ({ openModal,marshall, selectedRows, setOpenModal, units }) => {

  const [selectedUnit, setSelectedUnit] = useState()
  const [loading, setLoading] = useState()
  const [searchQuery, setSearchQuery] = useState()
  
  

  console.log(selectedUnit +"units selected")

    const data ={
      actionType:"AssignDeputyUnitCoodinator",
      emailTemplate:"",
      smIds:selectedRows,
      currentUnitId:0,
      newUnitId:selectedUnit,
    }
    const submitData =()=>{
      setLoading(true);
      massAction(data).then((res)=>{  
        console.log( res +"succes")
        toast.success("Assigned Succesfully")
        setOpenModal({
          modalName:"",
          showModal:false
        })
        setLoading(false)
    }).catch((err)=>{
        console.log(err)
        toast.error("Error something went wrong")
        setOpenModal({
          modalName:"",
          showModal:false
        })
        setLoading(false)
    })
    }
    // const filteredSM = marshall?.filter((sm)=> `${sm?.firstName} ${sm?.lastName}`== searchQuery)
  return (
    <div className="p-10 w-full">
      {/* <div>
        <select>
          <option onChange={(e)=>setsel}>Select a special marshial</option>
          {
            filteredSM?.map((sm)=>(
            <option value={sm?.id} key={sm?.id}>{sm?.firstName  + " " + sm?.lastName}</option>
            ))
          }
        </select>
      </div> */}
{/* 
      <div>
      <input type="text" placeholder="Search User" value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)}/>
      </div> */}


<h1 className=" uppercase font-bold w-full text-center mb-5">Assign as Deputy Unit Coordinator</h1>

      <h1 className=" uppercase font-bold mb-5"> Select Unit</h1>
      <div className="mb-5 w-full">
        <select value={selectedUnit} onChange={(e)=>setSelectedUnit(e.target.value)} className=" w-full py-3">
          <option>Select Unit</option>
          {
            units?.map((unit)=>(
              <option key={unit?.id} value={unit?.id}  className=" w-full ">{unit?.name}</option>
            ))
          }
        </select>
      </div>
      <div className="flex items-center justify-center gap-5 w-full">
        <button
          className="outline-none py-2 rounded-md border w-1/2 "
          onClick={() => setOpenModal({ modalName: "", showModal: false })}
          type="button"
        >
          Cancel
        </button>
        <button onClick={()=>submitData()} className="outline-none py-2 rounded-md w-1/2 bg-[#0D6EFD] text-white ">
         {loading ? <ScaleLoader color="white"/>:"Submit"}
        </button>
      </div>
    </div>
  );
};
