import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import bannerImage from "../../img/banner-img.png";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import Table from "../common/Table";
import { getAllMarshals } from "../../utils/marshals/getAllMarshal";
import { getUnits } from "../../utils/units/getUnits";
import { useNavigate } from "react-router-dom";
import { getSMCount } from "../../utils/marshals/getSMCount";

function AdminDashboard() {
  const {
    isEditing,
    setIsEditing,
    setMarshall,
    marshall,
    setUnits,
    units,
    auth,
    filterMarshalByUnitId,
  } = useAuth();
  const unitId = auth?.userDetails?.unitId;

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const [loading, setLoading] = useState(true);
  const [SmCount, setSmCount] = useState()
  // const currentRoute = typeof window !== "undefined" ? window.location.pathname : "";

  // const { useHandleApiError } = useHandleApiError(currentRoute);

  // useEffect(() => {
  //   if (auth?.roles !== "SpecialMarshal") {
  //     getAllMarshals().then((res) => {
  //       setLoading(false)
  //       setMarshall(res?.data?.data);
  //     });
  //     getUnits().then((res) => {
  //       setUnits(res?.data?.data);
  //     });
  //   }
  // }, []);
  // const handleReAuth = (error) =>{

  // useHandleApiError(error)
  // }
  const navigate = useNavigate();
  useEffect(() => {
  if (auth?.roles === "ADMINISTRATOR") {
    getAllMarshals().then((res) => {
      setLoading(false);
      setMarshall(res?.data?.data);
    }).catch((error) => {
      setLoading(false);
      console.error('Error fetching marshals:', error);
      // handleReAuth(error)
      navigate("/login")

    });
  // const navigate = useNavigate();

  // useEffect(() => {
 
    // getSMCount
    getUnits().then((res) => {
      setUnits(res?.data?.data);
      //  navigate("/login")
    }).catch((error)=>{
      navigate("/login")
    });
  } else if (auth?.roles === "UNITCOORDINATOR") {
    filterMarshalByUnitId(unitId)
      setLoading(false);
      getUnits().then((res) => {
      setUnits(res?.data?.data);
    }).catch((error)=>{
      navigate("/login")
    });
  }
}, []);


useEffect(() => {
  if (auth?.roles === "ADMINISTRATOR") {
    getSMCount().then((res) => {
      setLoading(false);
      setSmCount(res?.data);
    }).catch((error) => {
      setLoading(false);
      console.error('Error fetching marshals:', error);
      // handleReAuth(error)
      navigate("/login")

    });
  }
}, [])




  return (
    <div className="min-h-[92vh]">
      <WelcomeBanner />
      {auth?.roles === "SPECIALMARSHAL" ? (
        <MarshalCardInformations
          marshalCount={SmCount.totalCount || 0}
          unitsCount={units?.length}
          reportCount={0}
        />
      ) : (
        <CardInformations
          marshalCount={marshall?.length}
          unitsCount={units?.length}
          reportCount={0}
        />
      )}
      {auth?.roles !== "SPECIALMARSHAL" && <MarshalTable loading={loading} />}
    </div>
  );
}

export default AdminDashboard;

const WelcomeBanner = () => {
  const {auth} = useAuth()
  return (
    <div className="bg-[#C4DCFF] py-[20px] pl-[40px] rounded-[8px] flex justify-between">
      <div>
        <h3 className="text-white text-[25px] lg:text-[40px] font-bold mb-2 lg:mb-4">Hello</h3>
        <p className="text-white text-[20px] lg:text-[40px] mb-2 lg:mb-4">{auth?.userDetails?.firstName} {auth?.userDetails?.lastName}</p>
        <p className="text-[18px] lg:text-[23px] mt-[8px] lg:mt-[13px]">Welcome back</p>
      </div>
      <div className=" h-[100px] md:w-[150px] md:h-[150px]">
        <img src={bannerImage} alt="banner" className="w-full h-full" />
      </div>
    </div>
  );
};

const CardInformations = ({ marshalCount, unitsCount, reportCount }) => {
  const {
    isEditing,
    setIsEditing,
    setMarshall,
    marshall,
    setUnits,
    units,
    auth,
    filterMarshalByUnitId,
  } = useAuth();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[23px] my-[22px]">
      <div className=" h-[100px] md:h-[200px] bg-white flex  flex-col p-[20px] md:p-[30px] rounded-[8px]">
        <div className="flex items-center gap-[20px]">
          <div className=" md:w-[73px] p-2 md:h-[73px] rounded-full flex items-center justify-center bg-[#0D6EFD] text-white">
            <MdOutlinePeopleAlt size={50} />
          </div>
          <div className="text-black">
            <h3 className=" text-[12px] md:text-[16px] mb-3">Total Marshals</h3>
            <span className=" text-[30px] md:text-[57px] font-bold">{marshalCount || 0}</span>
            
          </div>
        </div>
        {/* <div className="text-black mt-4 flex justify-between">
          <div className="flex items-center gap-2">
            <p className="text-[13px]">Active</p>
            <span className="text-[23px] font-bold">1800</span>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-[13px]">Inactive</p>
            <span className="text-[23px] font-bold">200</span>
          </div>
        </div> */}
      </div>
      <div className=" h-[100px] md:h-[200px] bg-white flex  flex-col p-[20px] md:p-[30px] rounded-[8px]">
        <div className="flex items-center gap-[20px]">
          <div className="md:w-[73px] p-2 md:h-[73px] rounded-full flex items-center justify-center bg-[#FFCA00] text-white">
            <BiBuildingHouse size={50} />
          </div>
          <div className="text-black">
            <h3 className=" text-[12px] md:text-[16px] mb-3">Number of Units</h3>
            {
              auth?.roles === "UNITCOORDINATOR" ? (
                <span className="text-[30px] md:text-[57px] font-bold">---</span>
              ) : (
                <span className="text-[30px] md:text-[57px] font-bold">{unitsCount || 0}</span>
              )
            }
            
          </div>
        </div>
      </div>
      <div className=" h-[100px] md:h-[200px] bg-white flex  flex-col p-[20px] md:p-[30px] rounded-[8px]">
        <div className="flex items-center gap-[20px]">
          <div className=" p-2 md:p-0 md:w-[73px] md:h-[73px] rounded-full flex items-center justify-center bg-[#FF0000] text-white">
            <IoDocumentTextOutline size={50} />
          </div>
          <div className="text-black">
            <h3 className=" text-[12px] md:text-[16px] mb-3">Available Reports</h3>
            <span className=" text-[30px] md:text-[57px] font-bold">{reportCount || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
const MarshalCardInformations = ({ marshalCount, unitsCount, reportCount }) => {
  return (
    <div className="grid grid-cols-3 gap-[23px] my-[22px]">
      <div className="h-[200px] bg-white flex  flex-col p-[30px] rounded-[8px]">
        <div className="flex items-center gap-[20px]">
          <div className="w-[73px] h-[73px] rounded-full flex items-center justify-center bg-[#0D6EFD] text-white">
            <MdOutlinePeopleAlt size={50} />
          </div>
          <div className="text-black">
            <h3 className="text-[16px] mb-3">Available Reports</h3>
            <span className="text-[57px] font-bold">{marshalCount || 0}</span>
          </div>
        </div>
        {/* <div className="text-black mt-4 flex justify-between">
          <div className="flex items-center gap-2">
            <p className="text-[13px]">Active</p>
            <span className="text-[23px] font-bold">1800</span>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-[13px]">Inactive</p>
            <span className="text-[23px] font-bold">200</span>
          </div>
        </div> */}
      </div>
      <div className="h-[200px] bg-white flex  flex-col p-[30px] rounded-[8px]">
        <div className="flex items-center gap-[20px]">
          <div className="w-[73px] h-[73px] rounded-full flex items-center justify-center bg-[#FFCA00] text-white">
            <BiBuildingHouse size={50} />
          </div>
          <div className="text-black">
            <h3 className="text-[16px] mb-3">Available Documents</h3>
            <span className="text-[57px] font-bold">{unitsCount || 0}</span>
          </div>
        </div>
      </div>
      <div className="h-[200px] bg-white flex  flex-col p-[30px] rounded-[8px]">
        <div className="flex items-center gap-[20px]">
          <div className="w-[73px] h-[73px] rounded-full flex items-center justify-center bg-[#FF0000] text-white">
            <IoDocumentTextOutline size={50} />
          </div>
          <div className="text-black">
            <h3 className="text-[16px] mb-3">Available Bookings</h3>
            <span className="text-[57px] font-bold">{reportCount || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MarshalTable = ({loading}) => {
  const { marshall } = useAuth();
  return (
    <div className="bg-white rounded-[8px]">
      <div className="p-2 mt-2 text-black text-[16px] font-bold flex justify-between items-center">
        <p className="">Marshals</p>
        <a href="/dashboard/all-marshalls" className="block">
          View All
        </a>
      </div>
      <Table
        header={[
          { name: "First Name", identifier: "firstName" },
          { name: "Last Name", identifier: "lastName" },
          { name: "S/M Number", identifier: "smNumber" },
          { name: "Email Address", identifier: "emailAddress" },
          { name: "Phone Number", identifier: "phoneNumber" },
        ]}
        data={[...marshall?.splice(0, 5)]}
        options={{ variant: "primary", tableStyle: "", allowSorting: false }}
        loading
      />
    </div>
  );
};
