import React, { useState, useEffect } from 'react'
import { HiOutlineSearch } from 'react-icons/hi';
import { RxCaretLeft } from "react-icons/rx";
import Tabs from './Tabs';
import DocumentCardView from './DocumentCardView';
import defaultImage from "../../img/image.png";
import { getSMDocuments } from '../../utils/documents/getSMDocuments';
import useAuth from '../../hooks/useAuth';
import Loader from '../common/Loader';
import pdfImage from "../../img/pdf.png";
import docImage from "../../img/doc.png";
import xlsImage from "../../img/xls.png";
import ModalContainer from '../common/ModalContainer';
import AddDocument from '../Forms.js/AddDocument';
import AddSingleSMDocuments from '../Forms.js/addSingleSMDocuments';

function SingleMarshalDocumentView({ goBack, selectedUser }) {

      const [openUploadModal, setOpenUploadModal] = useState(false)
    const [activeTab, setActiveTab] = useState("All");
    const [filteredDocs, setFilteredDocs] = useState([])
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")
    const { auth } = useAuth()
    useEffect(() => {
        if (selectedUser) {
            setLoading(true)
            getSMDocuments(selectedUser?.id).then((res) => {
                setLoading(false)
                setDocuments(res?.data?.data)
            })
        }
    }, [])
    return (
        <div>
            <span onClick={() => goBack(false)} className='cursor-pointer'><RxCaretLeft size={30} color='black' /></span>
            <div className=' w-full flex justify-end gap-x-10 items-center mb-5 md:mb-[50px '>
            <div className='flex justify-between items-center ]'>
                <div className="relative">
                    <input type="text" placeholder="search" className="relative w-[200px] md:w-[327px] h-[45px] px-4 pl-8 rounded-[8px] border border-[#D9D9D9] outline-none" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    <HiOutlineSearch className="absolute top-[13px] left-[10px]" size={20} />
                </div>
            </div>
            <button className="bg-[#0D6EFD] text-white p-2 rounded-[8px] px-4 flex items-center gap-2 mt-4 lg:mt-0" onClick={() => setOpenUploadModal(true)}>Add New Document <span className="text-[18px]">+</span></button>
    
            </div>
            <div className=' flex justify-center'>
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} setFilteredDocs={setFilteredDocs} />
            </div>
            <div className='flex flex-col lg:flex-row gap-[34px] mt-[22px]'>
                <div className='md:w-[198px]  md:h-[243px] bg-white rounded-[8px] p-[8px] gap-5 md:gap-0 flex md:flex-col items-center'>
                    <div className=' w-[80px] mt-3 md:mt-0 h-[80px] md:w-[153px] md:h-[153px] rounded-full bg-blue-400 mb-[19px]'>
                        <img src={selectedUser?.profilePictureUrl} alt='' className='w-full h-full rounded-full object-cover' />
                    </div>
                    <p className=' text-[13px] md:text-[16px] text-black text-center'>{selectedUser?.firstName} {selectedUser?.lastName}</p>
                </div>
                {loading && <div className='w-full h-[200px] bg-white flex justify-center items-center'><Loader color="black" /></div>}
                {!loading &&
                    <div className="w-full lg:w-[924px] h-fit grid grid-cols-2 lg:grid-cols-6 place-items-center gap-[8px] gap-y-[16px] bg-white bg-opacity-40 p-[8px]">
                        {documents?.length > 0 ? documents?.filter((d) => searchTerm !== "" ? d?.actualFileName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) : d)?.filter((docs) => activeTab !== "All" ? docs?.documentType === activeTab : docs)?.map((doc) => (
                            <DocumentCardView key={doc?.id} docId={doc?.id} docImage={doc?.fileName.includes("pdf")
                                ? pdfImage
                                : doc?.fileName.includes("doc")
                                    ? docImage
                                    : doc?.fileName.includes("xls")
                                        ? xlsImage
                                        : defaultImage}
                                docName={doc?.fileName}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                docFileName={doc?.fileName}
                                imagePath={doc?.path} />)
                        ) : !loading && <div className="col-span-6"><p>No Documents Found</p></div>}
                    </div>
                }
            </div>

            {openUploadModal &&
      <ModalContainer>
        <AddSingleSMDocuments selectedUser={selectedUser} closeModal={setOpenUploadModal} />
      </ModalContainer>
    }
        </div>
    )
}

export default SingleMarshalDocumentView