import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideNav from "../AdminDashBoard/SideNav";
import "./style.css";
import useAuth from "../../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardNavbar from "../common/DashboardNavbar";
import DashboardSidebar from "../common/DashboardSidebar";
import useWindowSize from "../../hooks/useWindowSize";

function AdminLayout() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const {auth} = useAuth()
  const windowSize = useWindowSize();
  const navigate =useNavigate();

  useEffect(() => {
    if (auth == null) {
      navigate("/login")
    }
  }, [])
  
  return (
    <div className="grid grid-cols-12">
      {/* <SideNav /> */}
      <div
        className={`col-span-2 ${
          openSidebar ? "block left-0" : "hidden left-[-100%]"
        } lg:block duration-300`}
      >
        <DashboardSidebar
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
        />
      </div>
      <div className="col-span-12 lg:col-span-10">
        {/* <Navbar/> */}
        <div className="w-full lg:w-10/12 h-[60px] lg:h-[10%] px-8 flex items-center fixed top-0 z-20 bg-white">
          <DashboardNavbar
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
          />
        </div>
        <div className=" relative top-[60px] lg:top-[80px] bg-[#EEEEEE] p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
