import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FiTrash2 } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import "./Table.css";
import Loader from "./Loader";
import useWindowSize from "../../hooks/useWindowSize";
import { MdVerifiedUser } from "react-icons/md";
import { HiShieldExclamation } from "react-icons/hi2";
import { BiSolidFileExport } from "react-icons/bi";

function SMTable({
  header,
  data,
  options,
  openModal,
  setOpenModal,
  check,
  loading,
  rowData,
  setRowData,
  searchQuery,
  state,
  selectedRows,
  setSelectedRows,
  isActive,
  setIsActive,
}) {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedSM, setSelectedSM] =  useState([])


  console.log(selectedSM)
  const windowSize = useWindowSize();

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const filteredData = searchQuery
    ? data?.filter((item) =>
        Object?.values(item)?.some((value) =>
          value?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      )
    : data;

  // const sortedData = filteredData?.slice()?.sort((a, b) => {
  //   if (!sortBy) return 0;
  //   return sortOrder === "asc"
  //     ? a[sortBy]?.localeCompare(b[sortBy])
  //     : b[sortBy]?.localeCompare(a[sortBy]);
  // });
  const sortedData = filteredData?.slice()?.sort((a, b) => {
    if (!sortBy) return 0;
  
    if (sortBy === "isActive") {
      return sortOrder === "asc"
        ? a.isActive - b.isActive
        : b.isActive - a.isActive;
    }
  
    return sortOrder === "asc"
      ? a[sortBy]?.localeCompare(b[sortBy])
      : b[sortBy]?.localeCompare(a[sortBy]);
  });
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const exportToCSV = () => {
    if (!data || data.length === 0) {
      alert("No data available to export!");
      return;
    }
  
    const headers = header.map((h) => h.name).join(",");
    const csvRows = data.map((row) =>
      header.map((col) => `"${row[col.identifier] || ""}"`).join(",")
    );
  
    const csvContent = [headers, ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  

  return (
    <div className="max-w-[100vw] overflow-x-scroll">
      {/* <button 
  onClick={exportToCSV}
  className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition"
>
  Export CSV
</button> */}
      <table className="table overflow-x-auto">

        <thead className={options?.variant === "primary" ? "tablePrimaryBg" : "bg-white text-black"}>
          <tr>
            {check && (
              <th>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedRows(currentItems.map((row) => row.id)); // Select all visible items
                      setSelectedSM(currentItems.map((row) => row.id))
                    } else {
                      setSelectedRows([]);
                      setSelectedSM([]);
                    }
                  }}
                  checked={selectedRows.length === currentItems.length && currentItems.length > 0}
                />
              </th>
            )}
            {header?.map((item, index) => (
              <th
                key={index}
                className="font-semibold cursor-pointer w-fit"
                style={{ width: "auto", minWidth: "120px" }}
                onClick={() => options?.allowSorting && handleSort(item.identifier)}
              >
                {item.name} {sortBy === item.identifier && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
            ))}
            {options?.allowActions && <th className=" flex  justify-center items-center">Actions         <i title="export as csv" className="  cursor-pointer text-red-600 ml-10"
  onClick={exportToCSV}
>
<BiSolidFileExport />
</i></th>} 
    
          </tr>

   
        </thead>



  
        {/* Conditional rendering for the table body */}
        {state ? (
          <tbody>
            <tr>
              <td colSpan={header.length + (options?.allowActions ? 1 : 0)} className="text-  er !w-full col-span-3 justify-items-center !justify-center bg-white py-10">
                <Loader color="#0D6EFD" />
              </td>
            </tr>
          </tbody>
        ) : (
          

<tbody>
  {currentItems?.length > 0 ? (
    currentItems.map((row, rowIndex) => (
      <tr key={rowIndex} className={`${row?.isVerified === false ? " !line-through" : ""} ${options?.tableStyle === "striped-even" ? "even:bg-slate-200" : "bg-white"}`}>
        {check && (
          <td>
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedRows((prev) => [...prev, row.id]);
                  setSelectedSM((prev) => [...prev, row]);
                } else {
                  setSelectedRows((prev) => prev.filter((id) => id !== row.id));
                  setSelectedSM((prev) => prev.filter((id) => id !== row));
                }
              }}
              checked={selectedRows.includes(row.id)}
            />
          </td>
        )}
        {header.map((column, colIndex) => (
          <td key={colIndex} className="">
           {column?.identifier === "isActive" ? (
      <span
        className={`px-2 py-1 rounded-xl flex items-center justify-center w-[100px] text-xs font-semibold ${
          row.isActive ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"
        }`}
      >
        {row?.isActive ? "Active" : "Not Active"} {row?.isVerified === false ? <span title="Not verrified" className=" text-red-600"><HiShieldExclamation /></span> : <span title="verrified" className=" text-green-700"><MdVerifiedUser/></span>}
      </span>
            ) : (
              row[column.identifier]
            )}
          </td>
        ))}
        {options?.allowActions && (
          <td className="flex items-center justify-center gap-3">
            {options?.actions?.edit && (
              <span
                className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-blue-100 cursor-pointer"
                onClick={() => {
                  setOpenModal?.({ modalName: "edit", showModal: true });
                  setRowData?.(row);
                }}
              >
                <CiEdit size={16} />
              </span>
            )}
            {options?.actions?.delete && (
              <span
                className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-red-100 cursor-pointer"
                onClick={() => {
                  setOpenModal?.({ modalName: "delete", showModal: true });
                  setRowData?.(row);
                }}
              >
                <FiTrash2 size={14} color="red" />
              </span>
            )}
            {options?.actions?.view && (
              <span
                className="w-[2rem] h-[2rem] flex justify-center items-center rounded-full bg-blue-300 cursor-pointer"
                onClick={() => {
                  setOpenModal?.({ modalName: "view", showModal: true });
                  setRowData?.(row);
                }}
              >
                <IoEyeOutline size={15} />
              </span>
            )}
          </td>
        )}
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={header.length + (options?.allowActions ? 1 : 0)} className="text-center py-10 bg-white">
        No Results Found
      </td>
    </tr>
  )}
</tbody>


        )}

      </table>
        

        {options?.showPagination && (
      <div className="mt-4 flex justify-between items-center">
        {/* Items Per Page Selection */}
        <div className="flex items-center gap-2">
          <label className="text-[15px]">Items Per Page</label>
          <select
            className="bg-white p-2 rounded-md text-[13px]"
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1); // Reset to page 1 when items per page changes
            }}
          >
            {[10, 20, 30, 40, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>

        {/* Pagination Buttons */}
        {data?.length > itemsPerPage && (
          <div className="flex gap-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-200 px-3 py-1 rounded disabled:opacity-50"
            >
              Previous
            </button>

            {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => {
              const totalPages = Math.ceil(data.length / itemsPerPage);
              const maxPagesToShow = 5;
              let startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
              let endPage = Math.min(totalPages, startPage + maxPagesToShow);

              if (index >= startPage && index < endPage) {
                return (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`px-3 py-1 rounded ${
                      currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                    }`}
                  >
                    {index + 1}
                  </button>
                );
              }
              return null;
            })}

            <button
              onClick={() =>
                setCurrentPage((prev) =>
                  prev < Math.ceil(data.length / itemsPerPage) ? prev + 1 : prev
                )
              }
              disabled={currentPage >= Math.ceil(data.length / itemsPerPage)}
              className="bg-gray-200 px-3 py-1 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>
    )}

    </div>
  );
  
}

export default SMTable;
