import { createContext, useState } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const normalUser = JSON.parse(window.localStorage.getItem("normalUser"));
  const adminUser = JSON.parse(window.localStorage.getItem("adminUser"));
  const ApiUrl = window.localStorage.getItem("ApiUrl");
  // const allmarshals = JSON.parse(window.localStorage.getItem("marshals"));

  const [auth, setAuth] = useState(user);
  const [userRole, setUserRole] = useState(normalUser?.name);
  const [adminRoles, setAdminRoles] = useState(adminUser);
  const [myReports, setMyReports] = useState([]);
  const [marshall, setMarshall] = useState([]);
  const [getAllMarshallReports, setGetMarshallReports] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const [myDocuments, setMyDocuments] = useState([]);
  const [myManuals, setMyManuals] = useState([]);
  const [docType, setDocType] = useState([]);
  const [units, setUnits] = useState([]);
  const [loadUnits, setLoadUnits] = useState(false);
  const [userRoles, setUserRoles] = useState(null);

  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingManuals, setLoadingManuals] = useState(false);
  const [loadLogs, setLoadLogs] = useState(false);
  const [systemLogs, setSystemLogs] = useState([]);
  const [singleMarshal, setSingleMarshal] = useState({});
  const [allDocuments, setAllDocuments] = useState([]);
  const [loadingSm, setLoadingSm] = useState(false)

  const getAllMarshals = async () => {
    setLoading(true);
    setLoadingSm(true)
    await axios
      .get(ApiUrl + `/api/v1/users/getAllUsers`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setMarshall(res.data.data);
        setLoadingSm(false)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrMsg("Invalid request");
      });
  };

  const getMyReports = async () => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/api/v1/Reports/getMyReports`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setMyReports(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrMsg("Invalid request");
      });
  };

  const getAllReports = async () => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/api/v1/Reports/get`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setGetMarshallReports(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrMsg("Invalid request");
      });
  };

  const getMyDetails = async () => {
    await axios
      .get(
        ApiUrl +
          `/api/v1/SpecialMarshals/getById?id=${auth?.userDetails?.SpecialMarshalId}`,
        {
          headers: {
            Authorization: "Bearer " + auth?.token,
          },
        }
      )
      .then((res) =>
        setAuth((auth) => {
          let userDetails = { ...auth?.userDetails };
          userDetails = res.data.data;
          return { ...auth, userDetails };
        })
      );
  };

  const getMyDocuments = async () => {
    setLoadingDocuments(true);
    await axios
      .get(ApiUrl + `/api/v1/Documents/getMyDocuments`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setMyDocuments(res.data.data);
        setLoadingDocuments(false);
      })
      .catch((err) => {
        setLoadingDocuments(false);
      });
  };
  const getSMDocuments = async (userId) => {
    setLoadingDocuments(true);
    await axios
      .get(ApiUrl + `/api/v1/Documents/getSMDocuments?UserId=${userId}`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setMyDocuments(res.data.data);
        setLoadingDocuments(false);
      })
      .catch((err) => {
        setLoadingDocuments(false);
      });
  };
  const getMyManuals = async () => {
    setLoadingManuals(true);
    await axios
      .get(ApiUrl + `/api/v1/Documents/getManuals`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setMyManuals(res.data.data);
        setLoadingManuals(false);
      })
      .catch((err) => {
        setLoadingDocuments(false);
      });
  };
  const getDocumentTypes = async () => {
    await axios
      .get(ApiUrl + `/api/v1/Links/getDocumentTypes`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setDocType(res.data);
        console.log(res.data);
      })
      .catch((err) => {});
  };
  const getAllUnits = async () => {
    setLoadUnits(true);
    await axios
      .get(ApiUrl + `/api/v1/Units/get`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        setUnits(res.data.data);
        setLoadUnits(false);
        console.log(res.data);
      })
      .catch((err) => {
        setLoadUnits(false);
      });
  };
  const getRoles = async () => {
    await axios
      .get(ApiUrl + "/api/v1/users/getUserTypes")
      .then((res) => {
        let roles = res.data.data;
        const users = roles.map((user) => user?.name);
        setUserRoles(users);
      });
  };
  const handleLogout = () => {
    setAuth({});
    window.localStorage.removeItem("auth");
    window.location.href = "/login";
  };

  const REACT_APP_AWS_KEY = "AKIAUFEFAEXT6KRJFYPH";
  const REACT_APP_AWS_SECRET_KEY = "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";
  const REACT_APP_AWS_BUCKET_NAME = "marshalphotos";
  const updateProfile = async (e) => {
    const {
      FirstName,
      MiddleName,
      LastName,
      Email,
      UserName,
      PhoneNumber,
      Address,
      City,
      DoB,
      Paid,
      Profession,
      SpecialMarshalId,
      ProfilePictureUrl,
      AlternativeNumber,
      UnitId,
    } = auth?.userDetails;
    const Id = SpecialMarshalId;
    e.preventDefault();
    setLoading(true);
    await axios
      .put(
        ApiUrl + `/api/v1/SpecialMarshals/update`,
        {
          FirstName,
          MiddleName,
          LastName,
          GroupId: 0,
          Email,
          UserName,
          PhoneNumber,
          Address,
          City,
          DoB: DoB === null ? new Date().toLocaleDateString() : DoB,
          Paid,
          Profession,
          Id,
          ProfilePictureUrl,
          AlternativeNumber: AlternativeNumber === "" ? 0 : AlternativeNumber,
          UnitId,
        },
        {
          headers: {
            Authorization: "Bearer " + user?.token,
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setAuth((auth) => {
          let userDetails = { ...auth?.userDetails };
          userDetails = res.data.data;
          return { ...auth, userDetails };
        });
        setIsEditing(false);
        toast.success("Profile updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setErrMsg("Invalid request");
        console.log(err);
        toast.error(`${err.response.data.message}`);
      });
  };

  const filterMarshalByUnitId = async (unitId) => {
    setLoading(true);
    setLoadingSm(true)
    await axios
      .post(
        ApiUrl + `/api/v1/Users/getByUnitId`,
        { id: parseInt(unitId) },
        {
          headers: {
            Authorization: "Bearer " + user?.token,
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setMarshall(res.data.data);
        setLoadingSm(false)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setLoadingSm(false)
        setErrMsg("Invalid request");
      });
  };
  const filterMarshalById = async (userId) => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/api/v1/SpecialMarshals/getById?id=${userId}`, {
        headers: {
          Authorization: "Bearer " + user?.token,
        },
      })
      .then((res) => {
        setSingleMarshal(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setErrMsg("Invalid request");
      });
  };

  const getSystemLogs = async (id) => {
    setLoadLogs(true);
    const now = new Date();
    await axios
      .post(
        ApiUrl + `/api/v1/Logs/get`,
        {
          start: setHours(
            setMinutes(
              new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
              0
            ),
            1
          ),
          end: new Date(),
        },
        {
          headers: {
            Authorization: "Bearer " + user?.token,
          },
        }
      )
      .then((res) => {
        setLoadLogs(false);
        setSystemLogs(res.data.data);
      })
      .catch((err) => {
        setLoadLogs(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        userRole,
        setUserRole,
        adminRoles,
        setAdminRoles,
        marshall,
        setMarshall,
        loadingSm, 
        setLoadingSm,
        getAllMarshals,
        myReports,
        getMyReports,
        getAllMarshallReports,
        getAllReports,
        updateProfile,
        isEditing,
        setIsEditing,
        getMyDetails,
        loading,
        setLoading,
        errMsg,
        setErrMsg,
        filteredSuggestions,
        setFilteredSuggestions,
        activeSuggestionIndex,
        setActiveSuggestionIndex,
        showSuggestions,
        setShowSuggestions,
        input,
        setInput,
        ApiUrl,
        getMyDocuments,
        loadingDocuments,
        myDocuments,
        setMyDocuments,
        docType,
        setDocType,
        getDocumentTypes,
        myManuals,
        setMyManuals,
        getMyManuals,
        loadingManuals,
        getSMDocuments,
        REACT_APP_AWS_KEY,
        REACT_APP_AWS_SECRET_KEY,
        REACT_APP_AWS_BUCKET_NAME,
        getAllUnits,
        units,
        setUnits,
        loadUnits,
        filterMarshalByUnitId,
        getRoles,
        userRoles,
        setUserRoles,
        getSystemLogs,
        systemLogs,
        loadLogs,
        handleLogout,
        filterMarshalById,
        singleMarshal,
        setSingleMarshal,
        setMyReports,
        allDocuments,
        setAllDocuments,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
