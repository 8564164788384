import React, { useState } from 'react';
import logo from "../../img/logo3.png";
import { Link } from "react-router-dom";
import RegistrationForm from './reisterSmForm';
import { number } from 'yup';


const Signup = () => {
  const [Step, setCurrentStep] = useState(1);
   const [step, setStep] = useState(1);

 

  return (
    <div className='md:flex items-center justify-center h-full w-full mt-10'>
      <div className='my-20 md:my-0'>
      <a href='/' className=' flex justify-center mb-5'>
          <img src={logo} alt='logo' className=' w-[80px]' />
        </a>
        <div className=" hidden lg:grid mx-20 grid-cols-4 gap-3 shadow-sm mb-4">
          {
            [1,2,3,4].map((number)=>(
              <div key={number} className={` ${step === number &&(`!bg-[#0D6EFD]`)} bg-[#a1c4f7] py-[3px] rounded-xl`}></div>
            ))
          }
           {/* <div className=" bg-[#a1c4f7] py-[3px] rounded-xl"></div> */}
        </div>

        <RegistrationForm step={step} setStep={setStep} />
        
      </div>
    </div>
  );
};

export default Signup;
