import apiClient from "../../api/axios";

export const getAllRoles = async () => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  return await apiClient.get(`/api/v1/Users/getUserTypes`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
