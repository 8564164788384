import apiClient from "../../api/axios";

export const massActionBlob = async (data) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;

  return await apiClient.put(`/api/v1/Users/massUpdate`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
    responseType: "blob", // Ensure the response is a binary file
  });
};
