import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import logo from "../../img/frsc-logo.webp";

import Modal from "react-modal";
import { Link } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
import "./style.css";

function Lookup() {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "#ffffff5c";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [pin, setPin] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const ApiUrl = window.localStorage.getItem("ApiUrl");
  const handleSearch = (e) => {
    setLoading(true);
    const data = {
      value: pin
    }
    e.preventDefault();
    axios
      .post(ApiUrl + `/api/v1/users/getByPin`, data)
      .then((res) => {
        setLoading(false);
        setResult(res.data.data);
        setModalIsOpen(true);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          setErrMsg("Incorrect pin, Please try again");
        }
      });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setErrMsg("");
  }, [pin]);

  return (
    <>
      <Link to="/" className="">
        <button className="backHomBtn btn btn-primary">Go Home</button>
      </Link>
      <div className="lookup-container">
        <div className="search-container">
          {errMsg.length > 1 && <p className="search-error">{errMsg}</p>}
          <div className="searchField">
            <AiOutlineSearch style={{ fontSize: "2rem" }} />
            <input
              type="search"
              placeholder="PIN: e.g 0X-XXXXX"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
            <button
              className="btn btn-primary"
              disabled={loading}
              onClick={handleSearch}
            >
              {loading ? "Loading..." : "Lookup"}
            </button>
          </div>
        </div>
        {modalIsOpen && (
          <Modal
            isOpen={modalIsOpen}
            className="RModal"
            overlayClassName="Overlay"
            onRequestClose={closeModal}
          >
            <div className="">
              {loading ? (
                <p style={{ marginTop: 2 + "rem" }}>Please wait ...</p>
              ) : (
                <div style={{ marginTop: 2 + "rem" }}>
                  <div className="result-banner">
                    <button
                      className="btn btn-light m-4"
                      style={{ color: "white" }}
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <a href="/" className="flex-shrink-0" style={{marginLeft:"500px"}}>
                      <img
                        alt=""
                        src={logo}
                        style={{ height: "86px", marginBottom: "10px",marginRight:"496px"}}
                        className="   w-300 mb-[-300]"
                        
                      />
                    </a>
                    </div>
                  </div>
                  <UserDetails result={result} closeModal={closeModal} />
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default Lookup;

export const UserDetails = ({ result, closeModal }) => {
  return (
    <>
      <div className="result-container">
        <div className="result-content row">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "200px",
            }}
          ></div>

          <div className="result-image col-sm-4">
            <div className="profile-pic-container">
              <img
                style={{
                  width: 100 + "%",
                  objectFit: "cover",
                  borderRadius: 100 + "%",
                }}
                src={result?.profilePictureUrl || result?.passportPhotographUrl}
                alt=""
              />
            </div>
          </div>
          <div className="col-sm-8">
            <div style={{ minHeight: 15 + "rem" }} className="result-text row">
              <div className="col-sm-6">
                <h5>Pin</h5>
                <p className="r-text">{result?.smNumber}</p>
              </div>
              <div className="col-sm-6">
                <h5>Firstname</h5>
                <p className="r-text">{result?.firstName}</p>
              </div>
              <div className="col-sm-6">
                <h5>Lastname</h5>
                <p className="r-text">{result?.lastName}</p>
              </div>
              <div className="col-sm-6">
                <h5>Sex</h5>
                <p className="r-text">
                  {result?.sex}
                </p>
              </div>
              {result?.Unit && (
                <div className="col-sm-6">
                  <h5>Unit</h5>
                  <p className="r-text">{result?.unit}</p>
                </div>
              )}
              {result?.Position && (
                <div className="col-sm-6">
                  <h5>Position</h5>
                  <p className="r-text">{result?.position}</p>
                </div>
              )}
              <div className="col-sm-6">
                <h5>Designation</h5>
                <p className="r-text">{result?.userRole}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
