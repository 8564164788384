import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchEmailTemplate } from "../../utils/emailTemplates/fetchEmailTemplate";
import { massAction } from "../../utils/emailTemplates/massActions";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { FaTimes } from "react-icons/fa";
import { massActionBlob } from "../../utils/emailTemplates/massActionBlob";

const EmailSender = ({setOpenModal, selectedRows}) => {
  const [eTemplate, setETemplate] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [loading, setLoading] = useState()
  const [RecipientMail, setRecipientMail] = useState("")
  const [RecipientName, setRecipientName] = useState("")
  const [actionType, setActionType] = useState("SendEmail")
  const [response, setResponse] = useState()
  
  const [emailData, setEmailData] = useState({
    to: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    fetchEmailTemplate().then((res)=>(
      setETemplate(res?.data?.data)
    )).catch((err)=>(
      setETemplate(err)
    ))
  }, [])

  // console.log(selectedTemplate)

  console.log(response?.data,"ress")
  

  // const handleChange = (field, value) => {
  //   setEmailData({ ...emailData, [field]: value });
  // };

  // const sendEmail = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch("https://your-backend-api.com/send-email", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(emailData),
  //     });

  //     if (response.ok) {
  //       alert("Email sent successfully!");
  //       setEmailData({ to: "", subject: "", message: "" });
  //     } else {
  //       alert("Failed to send email.");
  //     }
  //   } catch (error) {
  //     alert("Error sending email.");
  //   }
  // };
  
  // const rr = "0046a5ea-56ad-4277-8e61-665208de2812","005232c4-afed-4daf-b974-a690b47b86a0"
  
  const data ={
    actionType:actionType,
    emailTemplate:selectedTemplate?.name,
    smIds:selectedRows,
    currentUnitId:0,
    newUnitId:0,
    recipientEmail:RecipientMail,
    recipientName:RecipientName
  }
  const sendEmail =()=>{
    setLoading(true);
    massAction(data).then((res)=>{  
      // console.log( res +"succes")
      toast.success("Email Sent")
      setResponse(res,'ress')

      actionType === "SendToSomeoneOrDownload" && (
        setResponse(res)
      )

      actionType  === "SendEmail" && (
        setOpenModal({
          modalName:"",
          showModal:false
        })
      )
      
(
        setOpenModal({
          modalName:"",
          showModal:false
        })
      )
      setLoading(false)
  }).catch((err)=>{
      console.log(err)
      // toast.error("Error sending Email")
      setOpenModal({
        modalName:"",
        showModal:false
      })
      setLoading(false)
  })
  }

  const sendEmailBlob =()=>{
    setLoading(true);
    massActionBlob(data).then((res)=>{  
      // console.log( res +"succes")
      toast.success("Email Sent")
      setResponse(res,'ress')

      actionType === "SendToSomeoneOrDownload" && (
        setResponse(res)
      )

      actionType  === "SendEmail" && (
        setOpenModal({
          modalName:"",
          showModal:false
        })
      )
      
(
        setOpenModal({
          modalName:"",
          showModal:false
        })
      )
      setLoading(false)
  }).catch((err)=>{
      console.log(err)
      // toast.error("Error sending Email")
      setOpenModal({
        modalName:"",
        showModal:false
      })
      setLoading(false)
  })
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };


  const handleChange = (e) => {
    let temp = eTemplate?.find((item)=>item?.name === e)
    setSelectedTemplate(temp);
    if(temp?.name === "Unverified_SMs"){
      setActionType("SendToSomeoneOrDownload")
    }
  }

  
  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="w-full flex justify-between items-center">
            <h2 className="text-xl font-semibold mb-4">Send Email</h2>
            <button
              onClick={() =>
                setOpenModal({
                  modalName: "",
                  showModal: false,
                })
              }
              className="text-red-600 text-[16px]"
            >
              <FaTimes />
            </button>
          </div>
      {response ? (
        <div>
          {/* <div dangerouslySetInnerHTML={{ __html: response?.data }} /> */}
          <h1>click button below to download sheet</h1>
          {/* <button
            onClick={() => {
              const element = document.createElement("a");
              const file = new Blob([response?.data], { type: "text/html" });
              element.href = URL.createObjectURL(file);
              element.download = "email_template.html";
              document.body.appendChild(element);
              element.click();
            }}
            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition"
          >
            Download
          </button> */}
          {/* <button
  onClick={() => {
    if (!response?.data) return;

    const element = document.createElement("a");
    const blob = new Blob([response.data], { type: response.headers["content-type"] || "application/octet-stream" });

    element.href = URL.createObjectURL(blob);
    element.download = response.headers["content-disposition"]
      ? response.headers["content-disposition"].split("filename=")[1]?.replace(/"/g, "") || "downloaded_file"
      : "downloaded_file";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }}
  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition"
>
  Download
</button> */}
<button
  onClick={async () => {
    try {
      const response = await massActionBlob(data); // Call API

      if (!response.data) {
        console.error("No data received");
        return;
      }

      // Extract filename from content-disposition header
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "download.zip"; // Default filename

      if (contentDisposition) {
        const matches = contentDisposition.match(/filename\*?=['"]?(?:UTF-8'')?([^;"']+)['"]?/);
        if (matches?.[1]) {
          fileName = decodeURIComponent(matches[1]); // Decode in case of UTF-8 encoding
        }
      }

      // Create a blob from the response
      const blob = new Blob([response.data], { type: "application/zip" });

      // Create a temporary anchor element for downloading
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName; // Use extracted filename

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up memory
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download failed", error);
    }
  }}
  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition"
>
  Download
</button>


          <button onClick={()=>{
            setResponse("")
            setRecipientMail("")
            setRecipientName("")
          }} className="mt-4 ml-5 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition"
        >
            Send Again
          </button>
        </div>
      ) : (
        <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        

          <div onSubmit={selectedTemplate?.name =="Unverified_SMs" ? sendEmailBlob : sendEmail} className="space-y-4">
            <div className="w-full flex gap-2">
              <select
                onChange={(e) => handleChange(e.target.value)}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-400 outline-none"
              >
                <option value="">Select Template</option>
                {eTemplate?.map((item) => (
                  <option key={item?.id} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>

            {selectedTemplate && (
              <div>
                <label className="font-semibold">Subject</label>
                <input
                  type="email"
                  placeholder="Recipient's Email"
                  value={selectedTemplate?.subject}
                  readOnly
                  className="w-full p-2 mb-5 border rounded-md focus:ring-2 focus:ring-blue-400 outline-none"
                  required
                />

                {selectedTemplate?.name === "Unverified_SMs" && (
                  <div>
                    {/* <input
                      type="email"
                      placeholder="Recipient's Email"
                      value={RecipientMail}
                      onChange={(e) => setRecipientMail(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-400 outline-none"
                      required
                    /> */}

<input
                      type="email"
                      placeholder="Recipient's Email"
                      value={RecipientMail}
                      onChange={(e) => setRecipientMail(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-400 outline-none"
                      required
                    />
                    {!validateEmail(RecipientMail) && RecipientMail && (
                      <p className="text-red-500 text-sm">Invalid email address</p>
                    )}
                    <input
                      type="text"
                      placeholder="Recipient Name"
                      value={RecipientName}
                      onChange={(e) => setRecipientName(e.target.value)}
                      className="w-full p-2 mb-5 border mt-5 rounded-md focus:ring-2 focus:ring-blue-400 outline-none"
                      required
                    />
                  </div>
                )}

                <ReactQuill
                  theme="snow"
                  value={selectedTemplate?.message}
                  readOnly
                  className="bg-white"
                />
              </div>
            )}
            <button
              type="submit"
              onClick={() => sendEmail()}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition"
            >
              {loading ? <ScaleLoader color="white" /> : "Send Email"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailSender;
